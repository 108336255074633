import React from "react";
import { Cookie } from "../../actions/util";

class Footer extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			end_page_list:[
				{type:"mainSite", link:'/faq/gamble-responsibly' , text : 'กฎและข้อกำหนด'},
				{type:"mainSite", link:'/faq/disclaimer',text:'ความรับผิดชอบ'},
				{type:"mainSite", link:'/faq/privacy-policy',text:'นโยบายความเป็นส่วนตัว'},
				{type:"mainSite", link:'/faq/betting-rules',text:'กฎทั่วไป'},
				{type:"outSite", link:'/lmr/th-th/',text:'พันธมิตร'}]
		}
	}
	


	/**
	 * 
	 * @param {String} link 
	 * @param {Number} key 
	 */
	openLink(link , siteType){
		// 如果 URL 中携带 affcode 则获取出来
		let aff = Cookie.GetCookieKeyValue('CO_affiliate') || '';
		let { mainSiteUrl } = this.props;
		try {
			let url = mainSiteUrl;
			switch (siteType) {
				case "outSite" :
					url = mainSiteUrl.replace('/th','') + link;
					break;
				case "mainSite" :
				default :
					url = mainSiteUrl + link + `${aff ? `?aff=${aff}` : ""}`
					break;
			}
			window.open( url, '_blank', 'noopener,noreferrer');
		} catch (error) {
			console.log('====================================');
			console.log(error);
			console.log('====================================');
		}
	}
	render(){
		return (
			<>
				<div className="footer-content-web">
					<div className={"partner"}>
						{/* 伙伴 */}
						<div className="item partner-item">
							<div className="title">หุ้นส่วน</div>
							<div className="image-box">
								<div>
									<div className="default partner-item-0"></div>
									<div className="footer-image-box-text">
									 	<a href="#" className="border">พันธมิตรการพนันอย่างเป็นทางการในเอเชีย</a>
									 	<a href="#">ท็อตแนมฮ็อทสเปอร์FC</a>
									</div>
								</div>
								<div>
									<div className="default parrtner-item-1"></div>
									<div className="footer-image-box-text">
									 	<a href="#" className="border">ผู้สนับสนุนเสื้ออย่างเป็นทางการ</a>
									 	<a href="#">นิวคาสเซิ่ลยูไนเต็ดFC</a>
									</div>
								</div>
							</div>
						</div>
						
						{/* 供应商 */}
						<div className="item suppliers">
							<div className="title">ผู้ให้บริการ</div>
							<div className="suppliers-box">
								<div className="suppliers-default suppliers-item-0"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-1"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-2"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-3"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-4"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-5"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-6"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-7"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-8"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-9"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-10"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-11"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-12"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-13"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-14"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-15"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-16"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-17"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-18"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-19"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-20"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-21"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-22"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-23"></div>
							</div>	
						</div>
						{/* 品牌形象大使 */}
						<div className="item brand">
							<div className="title">แบรนด์แอมบาสเดอร์</div>
							<div className="brand-default brand-item-0"></div>
						</div>
					</div>
					{/* 版权、 pay 、安全、责任*/}
					
					<div className="copyright-pay-safe">
						<div className="copyright">	
							<h6>ลิขสิทธิ์: </h6>
							<div className="icon"></div>
						</div>
						<div className="bank-pay">
							<h6>ทางเลือกการจ่ายเงิน: </h6>
							<div className='icon icon-1'></div>
							<div className='icon icon-2'></div>
							<div className='icon icon-3'></div>
							<div className='icon icon-4'></div>
							<div className='icon icon-5'></div>
							<div className='icon icon-6'></div>
						</div>
						<div className="safe">
							<h6>ความปลอดภัย: </h6>
							<div className="icon"></div>
						</div>
						<div className="responsibillity">
							<h6>ความรับผิดชอบ: </h6>
							<div>
								<div className="icon icon-1"></div>
								<div className="icon icon-2"></div>
							</div>
							
						</div>
					</div>
					
					{/* end-page */}
					<div className="end-page">
						{/* กฎและข้อกำหนด ความรับผิดชอบ นโยบายความเป็นส่วนตัว กฎทั่วไป พันธมิตร */}
						<ul className="end-page-list">
							{this.state.end_page_list.map((val , key)=>{
								return (
									<li onClick={()=>{this.openLink(val.link , val.type)}} className="item" key={key}>{val.text}</li>
								)
							})}
						</ul>
						
						{/* 帮助 和 contact 联系方式*/}
						<div className="help-contact">
							{/* 帮助 */}
							<div className="help">
								<img width="25" src={'/static/images/hayhar/icon-help.png'} className="help-icon" />
								<span onClick={()=>{this.openLink('/help/home.htm' , "mainSite")}}>ช่วยเหลือ</span>
							</div>
							{/* 联系 */}
							<div className='contact-box'>
								<a className="item" href="https://lin.ee/waGfQKv" target="_blank"></a>
								<img width="25" src={'/static/images/hayhar/flag-thb.png'} className="item country" />
							</div>
						</div>
						
						<div className="end-page-footer-text">
							<div className="certification">
                                <p>
                                    FUN88 ดำเนินการโดย Omega Sports Solutions N.V.<br/>
									FUN88 ได้รับอนุญาตและอยู่ภายใต้การกำกับดูแลของ <br className="footer-mobile-break"/>
                                    Curaçao Gaming Control Board<br/>
                                    สงวนลิขสิทธิ์โดย FUN88 ©
                                </p>
                                <a href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJak5LY0ZSWVNVTlVTbVo1ZG01RWJWRkdXRXRsYTJjOVBTSXNJblpoYkhWbElqb2lXR2RQY25aa1lqQTRaVkUzWjAxRWVGSnNVRmc1WnowOUlpd2liV0ZqSWpvaVpHUTRNV0kwTkRrME56UmpOekF5TldWbU16QTBNV1V4TURNelpUUmlZbUV4T1RNME9EQTRZMk15T1RGaVlUTmpOMkl6TkdZNU9EUTBOVGd6TmpFNE55SXNJblJoWnlJNklpSjk=" target="_blank">
                                    <img src={'/static/images/hayhar/eGamblingCert.png'} />
                                </a>
                                <p>Curaçao Gaming Control Board</p>
                            </div>
						</div>
					</div>
				</div>
				
			</>
		)
	}
}

export default Footer;
